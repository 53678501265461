<template>
   <div>
    <v-banner
        single-line
        height="400"
        class="banner"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/banner_IP400.jpg"
          height="100%"
          :transition="false"
          @load="handleLoad"
          class="d-flex align-center"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                width='8'
                color="grey lighten-3"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-banner>
    <div class="first">
       <div class="public-title">
        <p>ALL AREAS</p>
        <div>
          <span>全领域IP一站展示</span>
          <span>明星IP、游戏IP、影视IP、文旅IP等多种类型的IP资源，一对一签约合作</span>
        </div>
      </div>
       <v-tabs  centered  v-model="tab" fixed-tabs  hide-slider class="new-tabs-two">
          <v-tab v-for="(item,ind) in tablist" :key="ind">
            <b>{{ item.tab }}</b>
          </v-tab>
       </v-tabs>
      <v-tabs-items v-model="tab" >
       <v-tab-item
        v-for="(item,ind) in tabitem"
        :key="ind"
      >
        <v-container fluid >
          <v-row >
            <v-col v-for="content in item.content" :key="content.id" :cols="ind==0?'3':4">       
                <v-card class="mx-auto item-card justify-space-between" max-width="240" max-height="320" v-if='ind==0'>     
                  <v-img
                    max-height="320"
                    :src="content.img"
                    :aspect-ratio="9/16"
                  >
                  </v-img>
                </v-card>
                <v-card class="mx-auto item-card_two" :flat='true' max-width="300" max-height="325" v-if='ind!=0' style="text-align:left" @click="goCaseDetail(content)">     
                  <v-img
                    max-height="200"
                    :src="content.img"
                    :aspect-ratio="9/16"
                    style="border-radius: 10px;"
                  >
                  </v-img>
                  <v-card-title class="font-size-20 family-Bold mt-1">{{content.title}}</v-card-title>
                  <v-card-text class="font-size-14 family-normal subtitle-1 align-left" height='87'>{{content.sub}}</v-card-text>
                </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      </v-tabs-items>
    </div>
    <!-- 应用场景展示 -->
    <!-- <div class="second">
       <div class="public-title">
          <p>APPLICATION ACENARIO</p>
          <div>
            <span>应用场景展示</span>
        </div>
       </div>
        <div class="box">
            <div class="left"><img src="https://h5.ophyer.cn/official_website/other/Ipsq4.png" /></div>
            <div class="right">
              <v-card-text class="title">IP品牌运营</v-card-text>
              <v-card-text class="tip family-normal">虚拟人“活”灵“活”现，原本被动接受运营的消费者<br/>开始亲身参与交互，充分体验品牌IP魅力，拉动运营增<br/>长的全新智能触点</v-card-text>
            </div>
        </div>
    </div> -->
    <!-- 万景云产品方案 -->
    <v-card class="second" :flat="true">
      <div class="public-title">
        <p>APPLICATION ACENARIO</p>
        <div>
          <span>应用场景展示</span>
          <span>广泛的应用场景，赋能商业，打造差异化品牌IP</span>
        </div>
      </div>
      <div class="con">
        <v-carousel class="carousel-new" height="400" hide-delimiters :reverse="false" show-arrows-on-hover :cycle="true">
          <v-carousel-item
            v-for="(v, k) in Pro_list"
            :key="k"
          >
            <div class="box">
              <img :src="v.img" alt="" />
              <div>
                <p style="color:#333">{{ v.title }}</p>
                <p ></p>
                <p v-html='v.content' style="color:#666">{{ v.content }}</p>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-card>
    <!-- 行业态势 -->
    <div class="third">
       <div class="public-title">
          <p>PRODUCT ADVANTAGES</p>
          <div>
            <span>行业态势</span>
            <span>数字创意产业崛起，内容为王的时代，得IP者得天下</span>
           </div>
         </div>
       <v-card color="" flat class="third_card pa-2 mb-12" >
         <v-row  class="justify-center" align='center'>
           <v-col v-for='(item,ind) in ProductCard' :key='ind' cols='3'>
             <v-img :src='item.icon' width='120' style="top:38px;margin:-20px auto;z-index: 99"></v-img>
             <v-card height='300px' width="240" class="ProductCard" flat>
               <v-img :src='item.img' height='300px' aspect-ratio="1.4" >
                <v-card-title class="font-size-24 font-weight-bold justify-center mt-11">{{item.title}}</v-card-title>
                <v-card-text class="font-size-14" style="color:#666">{{item.content}}</v-card-text>
               </v-img>
             </v-card>
           </v-col>
         </v-row>
       </v-card>
    </div>
    <!-- 案例展示 -->
      <!-- <v-container fluid style="background: #F5F9FD;padding-top:1px" class="mt-10 pb-10" >
        <div class="public-title">
          <p> CASE SHOW</p>
          <div>
            <span>客户案例</span>
           </div>
         </div>
          <v-row class="case_row">
            <v-col v-for="item in cardslist" :key="item.id" cols="3">
              <v-hover v-slot="{ hover }">
                <v-card
                  class="mx-auto item-card" max-width="240" height="420">
                  <v-overlay
                    v-if="hover"
                    absolute
                    :opacity="0.8"
                    color="#000"
                  >
                    <v-card class="hover-card">
                      <div class="d-flex justify-center">
                        <div class="d-flex justify-center py-2" style="background:#FFF;width:170px;margin-top:55px">
                          <v-img :aspect-ratio="1" class="flex-grow-0" alt="二维码" width="160" height="160" contain src="@/assets/login/qrcode.png"></v-img>
                        </div>
                      </div>
                      <v-card-text class="sm white--text font-size-16" style='text-align:center'>
                        扫码体验
                      </v-card-text>
                    </v-card>
                    <v-card-text class="font-size-14 white--text mt-8" v-text="item.content"></v-card-text>
                  </v-overlay>
                  <v-img
                    max-height="340"
                    :src="item.src"
                    :aspect-ratio="9/16"
                  >
                  </v-img>
                  <div class="warp-icon">
                    <v-img :aspect-ratio="1" class="flex-grow-0" alt="二维码" width="50" height="50" contain src="@/assets/login/qrcode.png"></v-img>
                  </div>
                  <div class="text-box mt-5
                  " >
                    <v-card-text class=" font-size-14 " v-text="item.content" height='90'></v-card-text>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
      </v-container> -->
   </div>
</template>

<script>
import qs from 'qs'
export default {
  name: "IP",
  data(){
    return{
      model: 0,
      tab:null,
      windowHight: window.innerHeight/2,
      bannerLoad: false,
      imgLoad: false,
      tablist:[
        {tab:'明星IP'},
        {tab:'影视IP'},
        {tab:'游戏IP'},
        // {tab:'文旅IP'}
      ],
      tabitem:[{id:1,content:[
                        {id:1,img:'https://h5.ophyer.cn/official_website/other/ip-1.jpg'},
                        {id:2,img:'https://h5.ophyer.cn/official_website/other/ip-2.jpg'},
                        {id:3,img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg'},
                        {id:4,img:'https://h5.ophyer.cn/official_website/other/ip-4.jpg'},
                        {id:5,img:'https://h5.ophyer.cn/official_website/other/ip-5.jpg'},
                        {id:6,img:'https://h5.ophyer.cn/official_website/other/ip-6.jpg'},
                        {id:7,img:'https://h5.ophyer.cn/official_website/other/ip-7.jpg'},
                        {id:8,img:'https://h5.ophyer.cn/official_website/other/ip-8.jpg'}
                        ]
                },
                {id:2,content:[
                        {id:1,img:'https://h5.ophyer.cn/official_website/case/mofaxueyuanzhi-img.jpg',anli:'mofaxueyuanzhi',title:'魔法学院之见习女巫',sub:'国内首部魔幻学院题材电影《魔法学院之见习女巫》该片是由新锐导演梁锋执导，张纬驰、李一锋、林雨凡、姚威...'},
                        {id:2,img:'https://h5.ophyer.cn/official_website/case/xiongrenzhuan-img.jpg',anli:'xiongrenzhuan',title:'《熊人传》',sub:'专门报道食品安全的主持人海涛，在一次和动物管理员女友约会的场景中，无意被动物园中被犯罪集团研究的北极...'},
                        {id:3,img:'https://h5.ophyer.cn/official_website/case/xingmiyuzhou-img.jpg',anli:'xingmiyuzhou',title:'《星迷宇宙》大电影',sub:'某公司老板三金是一个超级英雄幻想家，每天在办公室里勾勒着超级英雄梦。而他的员工却觉得他是一个有问题的...'},
                ]},
  
                {id:3,content:[
                        {id:1,img:'https://h5.ophyer.cn/official_website/case/jiangshizhazhiji-img.jpg',anli:'jiangshizhazhiji',title:'僵尸榨汁机',sub:'《僵尸榨汁机》是一款既幽默搞笑又略重口味的僵尸题材休闲手游，本作将经营与冒险的元素结合起来，形成了一...'},
                        {id:2,img:'https://h5.ophyer.cn/official_website/case/taotaoxiong-img.jpg',anli:'taotaoxiong',title:'《涛涛熊极速联盟》',sub:'《涛涛熊极速联盟》是一款使用Unity 3D引擎制作的，全3D卡通风格乱斗类赛车游戏...'},
                        {id:3,img:'https://h5.ophyer.cn/official_website/case/shousirenzhe-img.jpg',anli:'shousirenzhe',title:'寿司忍者之忍者突袭',sub:'《寿司忍者之忍者突袭》是一款高速跑酷动作游戏，游戏中只需要触摸点击屏幕这一个步骤便可简单的进行游戏...'},
                ]},
  
                {id:4,content:[
                        {id:1,img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'魔法学院之见习女巫',sub:'国内首部魔幻学院题材电影《魔法学院之见习女巫》该片是由新锐导演梁锋执导，张纬驰、李一锋、林雨凡、姚威...'},
                        {id:2,img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'《熊人传》',sub:'专门报道食品安全的主持人海涛，在一次和动物管理员女友约会的场景中，无意被动物园中被犯罪集团研究的北极...'},
                        {id:3,img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'《星迷宇宙》大电影',sub:'某公司老板三金是一个超级英雄幻想家，每天在办公室里勾勒着超级英雄梦。而他的员工却觉得他是一个有问题的...'},
                        ]
                },
          ],
      list:[
        {img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'广泛的应用场景，赋能商业，打造差异化品牌IP',h2:'IP品牌运营',p:'虚拟人“活”灵“活”现，原本被动接受运营的<br/>消费者开始亲身参与交互，充分体验品牌IP魅<br/>力，拉动运营增长的全新只能触点'},
        {img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'数字创意产业崛起，内容为王的时代，得IP者得天下',h2:'虚拟导游',p:'针对景区导览、景区问讯等旅游场景需求，为游<br/>客提供景区导览、讲解等服务。帮助文旅品牌增<br/>强影响力，提供差异化服务，打造具有粘性的生态内容'},
        {img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'广泛的应用场景，赋能商业，打造差异化品牌IP',h2:'IP品牌运营',p:'虚拟人“活”灵“活”现，原本被动接受运营的<br/>消费者开始亲身参与交互，充分体验品牌IP魅<br/>力，拉动运营增长的全新只能触点'},
        {img:'https://h5.ophyer.cn/official_website/other/ip-3.jpg',title:'数字创意产业崛起，内容为王的时代，得IP者得天下',h2:'虚拟导游',p:'针对景区导览、景区问讯等旅游场景需求，为游<br/>客提供景区导览、讲解等服务。帮助文旅品牌增<br/>强影响力，提供差异化服务，打造具有粘性的生态内容'},
        ],
      cardslist: [
        {  src:'https://h5.ophyer.cn/official_website/other/ip-3.jpg', content:'第八届西塘汉服文化节',id:1},
        {  src:'https://h5.ophyer.cn/official_website/other/ip-3.jpg', content:'奥林匹克博览会',id:2},
        {  src:'https://h5.ophyer.cn/official_website/other/ip-3.jpg', content:'大美和谐 自在之境—千里江山图',id:3},
        {  src:'https://h5.ophyer.cn/official_website/other/ip-3.jpg', content:'为景区打造专属VR看景功能',id:4},
      ],
      ProductCard:[
        {img:'https://h5.ophyer.cn/official_website/other/IPhy31.png',icon:require('../../../public/img/icons/ip/IPicon21.png'),title:'政策扶持',content:'“十三五”规划将“数字创意产业”列为新一代五大新支柱之一；数字创意正逐步成为我国文化产业发展的新增长点'},
        {img:'https://h5.ophyer.cn/official_website/other/IPhy32.png',icon:require('../../../public/img/icons/ip/IPicon22.png'),title:'本土IP崛起',content:'随着国产IP迅猛发展与国潮复兴，国人对本土IP的认可度越来越高，给本土IP开启变现潮提供了坚实的基础'},
        {img:'https://h5.ophyer.cn/official_website/other/IPhy33.png',icon:require('../../../public/img/icons/ip/IPicon23.png'),title:'产业生态',content:'以IP为核心的文创产业正从内容融合向产业生态融合迈进,“IP+产业”全面融合、新技术广泛应用等方向推进'},
        {img:'https://h5.ophyer.cn/official_website/other/IPhy34.png',icon:require('../../../public/img/icons/ip/IPicon24.png'),title:'市场需求大',content:'产品运营、教育、文旅、直播等各行业对IP的需求量逐步增长，数字创意产业逐步崛起'}
      ],
      Pro_list:[
        {title:'IP品牌运营',content:'虚拟人“活”灵“活”现，原本被动接受运营的消费者开始亲身参与交互，充分体验品牌IP魅力，拉动运营增长的全新只能触点',img:'https://h5.ophyer.cn/official_website/other/IPsq3-1.png'},
        {title:'虚拟导游',content:'针对景区导览、景区问讯等旅游场景需求，为游客提供景区导览、讲解等服务。帮助文旅品牌增强影响力，提供差异化服务，打造具有粘性的生态内容',img:'https://h5.ophyer.cn/official_website/other/IPsq4-1.png'},
        {title:'虚拟客服',content:'IP 虚拟人化身虚拟客服，植入大屏一体机或Web页面，为用户提供问答服务。提供及时答复的同时，营造更加亲和自然的客服体验。',img:'https://h5.ophyer.cn/official_website/other/Ipsq3.png'},
        {title:'虚拟教师',content:'针对网络教学、在线解题等教育场景需求，IP 虚拟人化身为虚拟教师，植入平板或智慧教学屏等中小型硬件设备，为学生提供一对一的专属授课服务；降低教学人力成本，激发学生的学习兴趣',img:'https://h5.ophyer.cn/official_website/other/IPsq5-2.png'},
        {title:'虚拟主播',content:'针对直播、游戏讲解等媒体场景需求，IP 虚拟人化身为虚拟主播，为用户提供相应服务；利用虚拟主播，可降低直播的门槛、成本，打造更具话题和关注度的差异化品牌IP',img:'https://h5.ophyer.cn/official_website/other/IPsq2-1.png'},
        {title:'虚拟助手',content:'针对音乐播放、天气查询、闲聊对话等智能助手场景需求，IP 虚拟人化身虚拟助手，植入 IoT 硬件、手机 App 或车机等设备，为用户提供便捷的生活服务，成为能说会动的“全能型”智能助手。',img:'https://h5.ophyer.cn/official_website/other/IPsq1-1.png'},
      ]
    }
  },
   mounted(){
    // var _this = this;
    this.bannerLoad = true;

  },
  methods: {
    handleLoad(){
      this.imgLoad = true;
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.anli
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style scoped lang='scss'>
::v-deep .v-card__text,.v-card__title {
  line-height: 20px !important;
  padding:19px 0px 0px 0px!important;
}
::v-deep .v-card__subtitle, .text-subtitle-1{
  line-height: 20px !important;
  padding:1px 6px 6px 0;
}
.new-tabs-two .v-tab--active {
    color: #0568FD!important;
}
.new-tabs-two .v-tab {
    height: 50px !important;
}
.new-tabs-two .v-tabs-bar__content{
  padding: 0 !important;
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}

.banner_title {
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  padding-top: 10%;
}
.banner_span {
  font-family: Source Han Sans CN;
  color: #ffffff;
  font-size: 20px;
}
.content{
    padding: 24% 14% 3% 14%;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
    background: url(/img/bck.18dc8ae9.png) no-repeat center;
    background-size: cover;
    h2{
      font-size: 34px;
      margin-bottom: 20px;
    }
}
::v-deep .theme--light.v-tabs-items{
  max-width: 1200px;
  margin: 0 auto;
}
::v-deep .item-card {
    margin-bottom: 15px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    border-radius: 8px;
    position: relative;
    .warp-icon {
      position: absolute;
      right: 10px;
      bottom: 72px;
      width: 50px;
      height: 50px;
      background: #FFFFFF;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  
  }
 ::v-deep .item-card_two{
    &:focus{
      display: none;
     }
  }
.first{
  text-align: center;
  margin: 0 auto;
  max-width: 1300px;
  margin: 0 auto;
}
 .second{
  text-align: center;
  margin-top: 60px;
  margin-bottom: 60px;
  .con {
    max-width: 1200px;
    margin: 40px auto;
    text-align: start;
  }
  .box {
    padding: 20px 180px;
    height: 320px;
    
    margin-top: 39px;
    // cursor: pointer;
    background: #f5f9fd;
    border-radius: 30px;
    img {
      position: absolute;
      top: 0px;
      // width: 260px;
      // height: 260px;
      width: 400px;
      border-radius: 30px;
    }
    div {
      
      padding-left: 442px;
      p:nth-of-type(1) {
        
        margin-top: 78px;
        margin-bottom: 10px;
        font-size: 24px;
        //font-family: SourceHanSansCN-Bold;
        font-weight: bold;
        // color: #666;
      }
      p:nth-of-type(2) {
        margin-bottom: 20px;
        font-size: 20px;
        //font-family: SourceHanSansCN-Normal;
        font-weight: 400;
        white-space:nowrap;
        // color: #666;
      }
      p:nth-of-type(3) {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
.third{
  // margin: 60px auto;
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 70px;
  .v-card__text,.v-card__title {
    line-height: 20px !important;
    padding:19px 15px 0px 15px!important;
  }
  .ProductCard{
    background: rgba(206, 206, 206, 0);
    border: 1px solid #E9E9E9;
    border-radius: 30px 30px 0px 0px;
    margin: 0 auto;
    &:hover{
      border: 1px solid #BED3FF;
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1) !important;
    }
     &:focus{
      display: none;
     }
  }
  .third_card{
    margin: 0 auto;
  }
  .card_title{
    padding: 87px 10px 30px 10px
  }
}

::v-deep .v-btn--fab.v-size--x-large{
    width: 110px;
    top: 54px;
    z-index: 555;
    height: 110px;
}
::v-deep .v-carousel__controls{
  background:white
}
.case_row{
  max-width: 1200px;
  margin: 0 auto;
}

</style>